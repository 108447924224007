<script>
    import { required, email } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import validationMessages from '@/components/validations';
    import _ from 'underscore';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
   

    export default {
        components:{
            validationMessages,VueBootstrapTypeahead
        },
        watch: {
            'filterInput.email': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'email')}, 500),
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                demandOptions: ['delete'],
                demand: '',
                filterInput: {
                    email: "",
                },
                customerData: [],
                source: 'dashboard',
                showModal: false,
                tryingToEdit: false,
                submitted: false,
            };
        },
        validations: {
            filterInput: {
                email: {
                    required,
                    email
                }
            },
            demand: {
                required,
            },
        },
        methods: {

            async addCustomerRequest(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Customers.addRequest({
                            email: this.filterInput.email,
                            source: this.source,
                            demand: this.demand,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Request has been saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            async getCustomersAutocomplete(query, inputTypeName) {
                this.customerData = []

                if(query.trim().length > 0){
                  if(inputTypeName == 'email'){
                    query = 'email='+query
                  }

                  const response = await Customers.getCustomersByFilter(query)
                  const rawData = await response
                  var suggestions = [];
                  rawData.data.data.forEach(function (item) {
                    if(item.email && inputTypeName === 'email'){
                      suggestions.push({text:item.email});
                    }
                  });

                  this.customerData = suggestions.filter((value, index, self) => self.map(x => x.text).indexOf(value.text) == index)
                }
            },

            getFilters(){
              var filter_string = '&limit=10000';

              filter_string += (this.filterInput.email) ? '&email='+this.filterInput.email : "";
              return filter_string = filter_string.substring(1);
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            }
        }
};
</script>

<template>
    <b-modal v-model="showModal" id="add_request" title="Add Customer Request" title-class="font-18" centered>
        <form @submit.prevent="addCustomerRequest">
            <b-form-group label="Email" label-for="formrow-email-input" class="mb-3">

                <vue-bootstrap-typeahead id="formrow-email-input" ref="emailAutocomplete"
                                         v-model.trim="filterInput.email"
                                         :data=customerData
                                         :serializer="s => s.text"
                                         :class="{
                                            'is-invalid': submitted && $v.filterInput.email.$error,
                                         }"
                />
                <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.filterInput.email"></validationMessages>
            </b-form-group>

            <b-form-group label="Type" label-for="formrow-discount_type-input" class="mb-3">
                <b-form-select class="form-control" :class="{ 'is-invalid': submitted && $v.demand.$error}"
                               :options="demandOptions" v-model="demand"></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Demand'" :validationName="$v.demand"></validationMessages>
            </b-form-group>

        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addCustomerRequest" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>